import React from "react"
import PaddedContainer from "../ui/PaddedContainer"

import { formatCurrencyString } from "../../utils/format"
import { Product } from "../../ts/interfaces"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import { ProductGallery } from "./ProductGallery"
import ProductAddCart from "./ProductAddCart"
import ProductPrice from "./ProductPrice"
import Markdown from "markdown-to-jsx"
import { getCartProduct, getStripeId } from "../../utils/stripejs"
import { Hidden } from "@material-ui/core"
import { devices } from "../config/breakpoints"
import { useCheckoutState } from "../../checkout/state"

const ProductTitle = styled.h1`
  color: var(--color-black);
  font-size: 2.625rem;
  line-height: 3rem;
  margin: 0 0 0.25em 0;
  @media ${devices.mobile} {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
`
const Price = styled.h3`
  color: var(--color-teal);
  font-size: 1.1em;
  margin: 0;
  font-weight: 200;
  span {
    font-size: 1rem;
  }
`

const Subheading = styled.h4`
  color: var(--color-dark-blue);
  font-size: 0.9em;
  margin-bottom: 1em;
`

const ProductDetail = ({ product }: { product: Product }) => {
  const checkoutState = useCheckoutState()
  const { currency } = checkoutState

  const stripeId = getStripeId(product.stripeId, product.stripeTestId)

  const cartProd = getCartProduct(stripeId)
  if (cartProd === null || cartProd === undefined) {
    return <div />
  }

  const price = cartProd.prices[currency]

  if (price === null) {
    return <div />
  }

  let images = [product.featuredImage]
  if (product.gallery && product.gallery.length > 0) {
    images = [product.featuredImage, ...product.gallery]
  }

  const priceObj = {
    CAD_List_Price: product.CAD_List_Price!,
    CAD_Discount: product.CAD_Discount!,
    isBilledMonthly: product.isBilledMonthly,
    isBilledYearly: product.isBilledYearly,
    USD_List_Price: product.USD_List_Price!,
    USD_Discount: product.USD_Discount!,
    depositHeading: product.depositHeading!,
    priceHeading: product.priceHeading!,
    stripeId: product.stripeId!,
    stripeTestId: product.stripeTestId!,
    inventoryBreakpoints: product.inventoryBreakpoints,
  }

  return (
    <section className="section white">
      <PaddedContainer padding="3rem 1rem">
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={4}>
            <ProductGallery images={images} />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <ProductTitle>{product.name}</ProductTitle>
            {(!product.CAD_List_Price || !product.USD_List_Price) && (
              <Price>
                {formatCurrencyString({
                  value: price.unit_amount,
                  currency: currency.toUpperCase(),
                })}{" "}
                <span>{currency.toUpperCase()}</span>
              </Price>
            )}
            {product.isBilledMonthly && <Subheading>Billed Monthly</Subheading>}
            {product.isBilledYearly && <Subheading>Billed Yearly</Subheading>}
            <Subheading>{product.subHeading}</Subheading>
            {price && priceObj && <ProductPrice priceObj={priceObj} />}
            <Hidden mdUp>
              <div style={{ marginTop: "-2rem" }}>
                <ProductAddCart
                  product={product}
                  sectionId={`product_detail_${product.id}`}
                />
              </div>
            </Hidden>

            {product.description && <Markdown>{product.description}</Markdown>}

            <Hidden smDown>
              <ProductAddCart
                product={product}
                sectionId={`product_detail_${product.id}`}
              />
            </Hidden>
          </Grid>
        </Grid>
      </PaddedContainer>
    </section>
  )
}

export default ProductDetail
