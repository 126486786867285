import React, { ChangeEvent } from "react"
import { Product } from "../../ts/interfaces"
import { Button, Buttons } from "../ui/Button"
import { useLazyApi } from "../../hooks/useLazyApi"
import { useAuthState } from "../../contexts/authContext"
import Icon from "../ui/Icon"
import styled from "styled-components"
import Input from "../ui/Input"
import { getStripeId, getCartProduct } from "../../utils/stripejs"
import { useCheckoutState } from "../../checkout/state"

const CartConfrim = styled.div`
  margin-right: 2rem;
  margin-bottom: 1.5rem;
  font-size: 1.25em;
`

const QuantityWrapper = styled.div`
  width: 70px;
  margin-right: 3rem;
  margin-bottom: 1.5rem;
`

const BtnWrapper = styled.div`
  margin-bottom: 1.5rem;
  .btn {
    margin-right: 0 !important;
  }
`

const ProductAddCart = ({
  sectionId,
  product,
  showTitle,
}: {
  sectionId: string
  product: Product
  showTitle?: boolean
}) => {
  const authState = useAuthState()
  const checkoutState = useCheckoutState()
  const stripeId = getStripeId(product?.stripeId, product?.stripeTestId)
  const quantity =
    checkoutState.cartItems.find(item => item.productId === stripeId)
      ?.quantity || 0
  const cartProd = getCartProduct(stripeId)

  const [fetchOrders, { loading, error, data, errMsg }] = useLazyApi(
    "billing",
    "GetUserOrders"
  )

  const [qnty, setQuantity] = React.useState(1)
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10)
    if (value > 0) {
      if (cartProd && (cartProd.prices?.cad as any).type === "recurring") {
        setQuantity(1)
      } else {
        setQuantity(value)
      }
    }
  }

  let userHasMembership = false
  // is this a subscription ?
  if (cartProd && (cartProd.prices?.cad as any).type === "recurring") {
    if (!loading && data && data.User && data.User.membership) {
      userHasMembership = data.User.membership?.status !== "inactive"
    }
  }

  React.useEffect(() => {
    if (authState.isLoggedIn()) {
      const fetchAsync = async () => {
        await fetchOrders({
          variables: {
            email: authState.email,
          },
        })
      }
      fetchAsync()
    }
  }, [authState])

  return (
    <>
      {showTitle && <h2 style={{ textAlign: "center" }}>{product.name}</h2>}
      {userHasMembership && (
        <p>
          You already have an active membership. We only allow one membership
          purchase per customer.
        </p>
      )}
      {!userHasMembership && (quantity === 0 || !quantity) && (
        <Buttons>
          <QuantityWrapper>
            {(cartProd && (cartProd.prices?.cad as any)).type !==
              "recurring" && (
              <Input
                name="quantity"
                id="outlined-number"
                label="Quantity"
                type="number"
                size="small"
                value={qnty}
                onChange={handleChange}
                variant="outlined"
              />
            )}
          </QuantityWrapper>
          <BtnWrapper>
            <Button
              type="secondary"
              title="Add to Cart"
              sectionId={sectionId}
              id="add_cart"
              link={[{ product: product, addToCart: true }]}
              quantity={qnty}
            />
          </BtnWrapper>
        </Buttons>
      )}
      {!userHasMembership && quantity > 0 && (
        <Buttons>
          <CartConfrim data-aos="zoom-in" data-aos-duration="2000">
            <h4>
              <Icon
                iconName="check-circle"
                iconType="Solid"
                iconColor="var(--color-purple)"
              />{" "}
              Added to Cart
            </h4>
          </CartConfrim>
          <BtnWrapper>
            <Button
              sectionId={sectionId}
              id="checkout"
              data-aos="fade-left"
              data-aos-duration="1000"
              title="Checkout"
              type="primary"
              link={[{ url: "store/checkout" }]}
              gTagEvent="Returning Add to Cart"
            />
          </BtnWrapper>
        </Buttons>
      )}
    </>
  )
}

export default ProductAddCart
