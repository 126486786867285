import React, { useState } from "react"
import Carousel from "../ui/Carousel"

import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Image } from "../../ts/interfaces"
import { Grid, Hidden } from "@material-ui/core"
import { devices } from "../config/breakpoints"
import { Button } from "../ui/Button"

const Thumbnail = styled.div`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--color-grey-3);
  cursor: pointer;
  :hover {
    border: 1px solid var(--color-teal);
  }
`
const ThumbImage = styled(props => <GatsbyImage {...props} />)`
  width: 82px;
  height: 65px;
`

const ImageWrapper = styled.div`
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`

const ProductImage = styled(props => <GatsbyImage {...props} />)`
  border-radius: 20px;
  img {
  }
`

const ThumbContainer = styled.div`
  margin-top: 2rem;
`

const ProductGalleryWrapper = styled.div`
  @media ${devices.mobilePhone} {
    margin: -3rem 0;
  }
  position: relative;
  #right_productgallery {
    position: absolute;
    top: 45%;
    right: -2.75rem;
    z-index: 200;
  }
  #left_productgallery {
    position: absolute;
    top: 45%;
    left: -2.75rem;
    z-index: 200;
  }
`

export const ProductGallery = ({ images }: { images: Image[] }) => {
  const [index, setIndex] = useState(0)

  return (
    <ProductGalleryWrapper>
      <Grid
        container
        spacing={6}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
      >
        <Hidden only="sm">
          <Grid item xs={12} sm={7} md={12}>
            <Hidden smUp>
              <Button
                onClick={() => setIndex(index - 1)}
                type="textSecondary"
                icon="chevron-left"
                size="regular"
                sectionId="productGallery"
                id="left"
                disabled={index <= 0}
              />
            </Hidden>
            <Carousel autoPlay={false} index={index} indicators={false}>
              {images.map((img, index) => (
                <ImageWrapper key={index}>
                  <ProductImage
                    image={
                      img &&
                      img.imageFile &&
                      img.imageFile.childImageSharp.gatsbyImageData
                    }
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={img && img.alternativeText}
                  />
                </ImageWrapper>
              ))}
            </Carousel>
            <Hidden smUp>
              <Button
                onClick={() => setIndex(index + 1)}
                type="textSecondary"
                icon="chevron-right"
                size="regular"
                sectionId="productGallery"
                id="right"
                disabled={index >= images.length - 1}
              />
            </Hidden>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item xs={12} sm={5} md={12}>
            <ThumbContainer>
              {images.map((img: Image, i: number) => (
                <Thumbnail key={i} onClick={() => setIndex(i)}>
                  <ThumbImage
                    image={
                      img &&
                      img.imageFile &&
                      img.imageFile.childImageSharp.gatsbyImageData
                    }
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={img && img.alternativeText}
                  />
                </Thumbnail>
              ))}
            </ThumbContainer>
          </Grid>
        </Hidden>
        <Hidden xsDown mdUp>
          <Grid item xs={12} sm={7} md={12}>
            <Carousel autoPlay={false} index={index} indicators={false}>
              {images.map((img, index) => (
                <ImageWrapper key={index}>
                  <ProductImage
                    image={
                      img &&
                      img.imageFile &&
                      img.imageFile.childImageSharp.gatsbyImageData
                    }
                    objectFit="contain"
                    objectPosition="50% 50%"
                    alt={img && img.alternativeText}
                  />
                </ImageWrapper>
              ))}
            </Carousel>
          </Grid>
        </Hidden>
      </Grid>
    </ProductGalleryWrapper>
  )
}
