import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import { Product } from "../ts/interfaces"
import ProductDetail from "../components/products/ProductDetail"
import ProductAddCart from "../components/products/ProductAddCart"
import PaddedContainer from "../components/ui/PaddedContainer"
import SEO from "../components/Seo"
import Hero from "../components/sections/Hero"
import Section from "../components/Sections"
import { useTrackProductView } from "../hooks/useTrackProductView"
import { LoadCampaignAffiliateData } from "../utils/campaignAffiliateData"

const Page = ({
  pageContext,
  data: {
    strapi: { product },
  },
}: {
  pageContext: {
    canonicalUrls?: string[]
    indexStorePage?: boolean
  }
  data: {
    strapi: {
      product: Product
    }
  }
}) => {
  const { sections } = product

  const hero = sections.find(
    data => data.__typename === "STRAPI_ComponentZonesHeroZone"
  )

  const hasHero = hero !== null && hero !== undefined

  const trackProductView = useTrackProductView(product)
  LoadCampaignAffiliateData()

  useEffect(() => {
    trackProductView()
  }, [])

  return (
    <Layout hasHero={false} showStickFooter={false}>
      <SEO
        title={product.name}
        metaTitle={product.seo?.metaTitle}
        description={product.seo?.metaDescription}
        image={getSrc(
          product.seo?.metaImage?.imageFile.childImageSharp.gatsbyImageData
        )}
        canonicalUrls={pageContext.canonicalUrls}
        meta={[
          {
            name: "robots",
            content:
              pageContext.indexStorePage === false
                ? "noindex"
                : "index, follow",
          },
        ]}
      />
      <>
        <ProductDetail product={product} />
        {hasHero && <Hero {...hero} />}
        {sections.map((section, index) => (
          <Section
            key={`${section.__typename}-${section.id}`}
            section={{
              ...section,
            }}
            delayIndex={hasHero ? index : undefined}
          />
        ))}
        <PaddedContainer padding="2rem 4rem">
          <ProductAddCart
            product={product}
            showTitle={false}
            sectionId={`p${product.id}`}
          />
        </PaddedContainer>
      </>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ProductById($id: ID!) {
    strapi {
      product(id: $id) {
        id
        name
        stripeId
        stripeTestId
        isBilledMonthly
        isBilledYearly
        slug
        subHeading
        shortDescription
        description
        seo {
          metaTitle
          metaDescription
          metaImage {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FIXED
                  quality: 80
                  width: 1200
                )
              }
            }
          }
        }
        featuredImage {
          url
          alternativeText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FULL_WIDTH
                quality: 80
              )
            }
          }
        }
        gallery {
          url
          alternativeText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FULL_WIDTH
                quality: 80
              )
            }
          }
        }
        CAD_List_Price
        CAD_Discount
        USD_List_Price
        USD_Discount
        depositHeading
        priceHeading
        inventoryBreakpoints {
          id
          breakpointText
          breakpointStart
          breakpointEnd
          textColor
        }
        sections {
          __typename
          ... on STRAPI_ComponentZonesHeroZone {
            id
            heading {
              text
              colour
              align
            }
            subheading {
              text
              colour
              align
            }
            more
            contentAlign
            displayType
            backgroundImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
            mobileBackgroundImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 30
                  )
                }
              }
            }
            featuredVideo {
              youtubeId
              youtubeMobileId
            }
            button {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesCardZone {
            id
            columns
            maxWidth
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            card {
              id
              title
              description
              iconName
              iconType
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesSpecZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            specCard {
              id
              title
              description
              countTo
              countSuffix
              strikethrough
              countPrefix
              countLabel
            }
          }
          ... on STRAPI_ComponentZonesStepsZone {
            id
            description
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            steps {
              title
              iconName
              iconType
              state
            }
            buttons {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesSimpleContentZone {
            id
            content
            align
            alignButtons
            maxWidth
            largeFormat
            inlineMedia {
              referenceName
              rounded
              align
              width
              youtubeId
              youtubeMobileId
              image {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              mobileImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            buttons {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            featuredImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 70
                  )
                }
              }
            }
            featuredVideo {
              youtubeId
              youtubeMobileId
            }
          }
          ... on STRAPI_ComponentZonesTestimonialsZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            version
            start
            testimonials {
              id
              avatar {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FIXED
                      quality: 80
                      height: 100
                      width: 100
                    )
                  }
                }
              }
              author
              company
              quote
            }
          }
          ... on STRAPI_ComponentZonesFeaturesZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            description
            image {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
            cards {
              id
              title
              iconType
              iconName
              description
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesInteractiveFeaturesZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            description
            cards {
              id
              title
              iconType
              iconName
              description
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
