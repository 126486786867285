import { getCartProduct, getStripeId } from "../utils/stripejs"
import { Product } from "../ts/interfaces"
import { klaviyoTrackBehavior } from "../utils/klaviyo"
import { sendPixelEvent } from "../utils/pixel"

import { useLazyApi } from "./useLazyApi"
import { useCheckoutState } from "../checkout/state"

export const useTrackProductView = (product: Partial<Product>) => {
  const checkoutState = useCheckoutState()
  const { currency } = checkoutState
  const [
    sendPxEvent,
    { loading: sendLoading, error: sendError, data: sendData },
  ] = useLazyApi("website", "sendPixelEvent")

  const trackProductView = () => {
    if (typeof window !== `undefined` && product) {
      const stripeId = getStripeId(product.stripeId, product.stripeTestId)
      const cartProd = getCartProduct(stripeId)
      if (!cartProd) {
        return
      }
      const price =
        (cartProd && cartProd.prices && cartProd.prices[currency]) || null

      sendPixelEvent("ViewContent", sendPxEvent, {
        items: [{ ...cartProd!, quantity: 1 }],
        total: 0,
        currency,
      })

      if (window._learnq) {
        klaviyoTrackBehavior("Viewed Product", {
          content_type: "product",
          content_ids: [cartProd!.id],
          content_name: cartProd!.name,
          ...(price && {
            value: parseFloat((price.unit_amount / 100).toFixed(2)),
          }),
          currency: currency,
        })
      }
    }
  }
  return trackProductView
}
